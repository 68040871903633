import React from "react"
import Base from "./Base"
import ComponentParser from "../helpers/ComponentParser"

import contentParser from 'gatsby-source-wpgraphql-images'

import { pluginOptions } from "../helpers/helpers"

const wordPressUrl = pluginOptions.wordPressUrl
const uploadsUrl = pluginOptions.uploadsUrl

const PageTemplate = ({ pageContext }) =>  {

  const {contentFiles, contentParsed} = pageContext
  
  return (
    <Base context={pageContext}>
    {/* {pageContext.isFrontPage ? <ComponentParser key={pageContext} content={pageContext.blocks} /> : <section className="section-bottom"><div className="inner-div"><div className="page-content" dangerouslySetInnerHTML={{ __html: pageContext.content }} /></div></section>} */}
    {pageContext.isFrontPage ? <ComponentParser key={pageContext} content={pageContext.blocks} contentParsed={contentParsed} contentFiles={contentFiles} /> : <section className="section-bottom"><div className="inner-div"><div className="page-content">{contentParser({ content: contentParsed, files: contentFiles }, { wordPressUrl, uploadsUrl })}</div></div></section>}
    </Base>
  )
}

export default PageTemplate